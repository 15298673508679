<template>
 <CCol>
  <CToaster :autohide="2000">
        <template>           
        <div v-if="showSuccessToast">
          <CToast          
          :show.sync="showSuccessToast"
          color="success"
          >
            {{ successToastMessage }}
          </CToast>
        </div>   
      </template>         
      <template>           
        <div v-if="showErrorToast">
          <CToast          
          :show.sync="showErrorToast"
          color="danger"
          >
            {{ errorToastMessage }}
          </CToast>
        </div>   
      </template>
  </CToaster>        
  <CRow v-show="loading" style="max-width: 80rem;">
    <CCol col="12">
      <CCard v-show="loading">
        <CCardBody>
          <p class="text-center" v-show="loading">
            <CSpinner />
          </p >   
        </CCardBody>
      </CCard>
    </CCol>    
  </CRow>
  <CRow v-show="!loading" style="max-width: 80rem;"> 
    <CCol col="12">     
      <CCard>
        <CCardHeader>
          <b>Update Workload Lenses for {{ workload.FriendlyName }}</b>
        </CCardHeader>
        <CCardBody>
          <CTabs variant="tabs" :active-tab="0">
              
            <CTab :title="getLensName(lens.lens_alias)" :key="lens.lens_alias" v-for="lens in lensesVersionDifference">
              <CCol :key="pillar.pillar_id" v-for="pillar in lens.version_differences.pillar_differences">
                <CCardHeader>
                  <b>{{ getPillarName(pillar.pillar_name) }}</b>
                </CCardHeader>
                <CCardBody>   
                  <p>This pillar has been updated. See the changes below for more details.</p>
                  <CDataTable
                    stripped
                    :header="true"
                    :items="pillar.question_differences"
                    :sorterValue="{ column: 'difference_status', asc: 'desc' }"
                    :fields="questionDifferencesTableFields"
                    :pagination="false"
                    :loading=loadingLensesVersionDifference
                  >
                    <td slot="difference_status" slot-scope="{item}" >                  
                      <CBadge :color="getBadge(item.difference_status )">
                        {{ item.difference_status }}
                      </CBadge>                      
                      
                    </td>                   
                  </CDataTable>
                </CCardBody>       
              </CCol>
            </CTab>
          </CTabs>
        </CCardBody>
        <CCardFooter>
          <CRow>
            <CCol col="12">
              <p class="ml-5 mr-5">When you upgrade the lens, any changes to questions and choices are applied to your review. Your notes for each question, and the choices you selected, are retained if they still apply in the upgraded lens.</p>
              <p class="ml-5 mr-5">Your current state is saved as a milestone for future reference.</p>
            </CCol>
          </CRow>          
          <CRow>
            <CCol col="12">
            <CButton color="light" class="float-left  col-sm-2 col-xl-1" @click="goBack">Back</CButton>
            <CButton v-on:click="updateWorkload(workload.WorkloadId);" color="primary" class="ml-5 float-right col-sm-4 col-xl-2" :disabled="!acknowledgedUpdate" >Update</CButton>
            <CInputCheckbox
              label="I understand and accept these changes"
              name="Check to update all Lenses."
              v-model="acknowledgedUpdate"
              class="mt-1 float-right"
              v-c-tooltip="{content: 'This will update all Lenses which are assigned to the Workload. This can not be reverted! ', placement: 'left'}"
            />
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>

        
    </CCol>    
  </CRow> 
  </CCol>    
</template>


<script>
import WorkloadService from '@/services/workload.services';
import LensService from '@/services/lense.services';


export default {
  components: {
  },  
  data() {
    return {

      acknowledgedUpdate: false,
      loading: true,
      loadingLensesVersionDifference: true,
      lenses: [],
      pillars: [],
      workload: {},      
      workloadNotifications: [],
      lensesVersionDifference: [],
      questionDifferencesTableFields: [
        { key: 'question_title', label: 'Question', _style:'width:90%', sorter: false, filter: false  },
        { key: 'difference_status', label: 'Status', _style:'width:10%', sorter: false, filter: false  },
      ],  
      showSuccessToast: false,
      showErrorToast: false,
      successToastMessage: "",
      errorToastMessage: "",
    

    };
  },
  methods: {         
    goBack() {
      this.$router.push({path: '/workloads'})
    },  
    getLensName(lensAlias) {
      let lens = this.lenses.find(o => o.lens_alias === lensAlias);

      if (lens === undefined) {
        return lensAlias
      }

      return lens.lens_name;
    
    },  
    getPillarName(pillarId) {
      let pillar = this.pillars.find(o => o.pillar_id === pillarId);

      if (pillar === undefined) {
        return pillarId
      }

      return pillar.pillar_name;
    
    },  
    getBadge (status) {
      switch (status) {
        case 'NEW': return 'success'
        case 'UPDATED': return 'info'
        case 'DELETED': return 'danger'
        default: 'secondary'
      }
    },         
    async updateWorkload(workloadId) {
      this.acknowledgedUpdate = false;
      try {
        for (var key in this.lensesVersionDifference) {
          let currentLensVersion = this.lensesVersionDifference[key].base_lens_version;
          let targetLensVersion = this.lensesVersionDifference[key].target_lens_version;
          let lensAlias = this.lensesVersionDifference[key].lens_alias;
          await WorkloadService.updateWorkloadLens(workloadId, lensAlias, currentLensVersion, targetLensVersion).then(
            response => { 
              this.successToastMessage = "Workload Lens "+ this.getLensName(lensAlias) + " updated successfully.";
              this.showSuccessToast = true;
            },
            error => {   
              console.log(error);
              this.errorToastMessage = "Error updating Workload Lens " + this.getLensName(lensAlias) + ". Please try again later.";
              this.showErrorToast = true;
              throw error;
            }
          )
        }  
        this.successToastMessage = "All Workload Lens updated successfully.";
        this.showSuccessToast = true;      
        this.$router.push({path: '/workloads'})        
      } catch(error) {
        this.errorToastMessage = "Error updating Workload Lenses. Please try again later.";
        this.showErrorToast = true;
      }    



    },     
    async getLensVersionDifference(lensAlias, baseLensVersion, targetLensVersion) { 
      try {
        let response = await LensService.getLensVersionDifference(lensAlias, baseLensVersion, targetLensVersion);
        return response;

      } catch (error) {
        console.log(error);
        this.errorToastMessage = "Error getting Lens Version Difference. Please try again later.";
        this.showErrorToast = true;
      };
    },
    
    async processWorkloadNotifications() {
     
     for (var key in this.workloadNotifications){
      if(this.workloadNotifications[key].type === "LENS_VERSION_UPGRADED" ){
        let lensUpgradeSummary = this.workloadNotifications[key].lens_upgrade_summary;
        let lensVersionDifference = await this.getLensVersionDifference(lensUpgradeSummary.lens_alias, lensUpgradeSummary.current_lens_version, lensUpgradeSummary.latest_lens_version);
        this.lensesVersionDifference.push(lensVersionDifference);
      }     
      this.loadingLensesVersionDifference = false;
     }
    }
  },
  created() { },
  async mounted() { 
    await LensService.getLenses().then(
      response => { 
        this.lenses = response.lens_summaries;
      },
      error => {   
        console.log(error);
        this.errorToastMessage = "Error getting Lenses. Please try again later.";
        this.showErrorToast = true;
      }      
    );    
    await LensService.getPillars().then(
      response => { 
        this.pillars = response.pillars;
      },
      error => {   
        console.log(error);
        this.errorToastMessage = "Error getting Pillars. Please try again later.";
        this.showErrorToast = true;
      }      
    );    
    await WorkloadService.getWorkload(this.$route.params.id, true).then(
      response => { 
        this.workload = response;
      },
      error => {   
        console.log(error);
        this.errorToastMessage = "Error getting Workload. Please try again later.";
        this.showErrorToast = true;
      }      
    )
    await WorkloadService.getWorkloadNotifications(this.$route.params.id).then(
      response => { 
        this.workloadNotifications = response.notifications;
      },
      error => {   
        console.log(error);   
        this.errorToastMessage = "Error getting Workload Notifications. Please try again later.";
        this.showErrorToast = true;
      }      
    )
    await this.processWorkloadNotifications();
    this.loading = false;

  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },


};



</script>